import type { FocusableProps, ItemProps, Node } from '@react-types/shared';
import type { ElementType } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { use, useMemo, useRef } from 'react';

import { FocusRing } from '@react-aria/focus';
import { isFocusVisible, useHover } from '@react-aria/interactions';
import { useOption } from '@react-aria/listbox';
import { mergeProps } from '@react-aria/utils';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { dataFocusVisibleClasses } from '../../utils';
import { FreeSoloCheckbox } from '../Checkbox/FreeSoloCheckbox';
import { ListBoxContext } from './ListBoxContext';

export const listBoxItemVariants = tv({
  slots: {
    base: [
      'flex',
      'cursor-pointer',
      'items-center',
      'gap-2',
      'rounded-md',
      'data-[selected=true]:bg-actions-selected',
      'data-[selected=true]:font-semibold',
      'data-[pressed=true]:bg-actions-hover',
      'data-[focus=true]:bg-actions-focus',
      'data-[hover=true]:bg-actions-hover',
      'data-[disabled=true]:cursor-auto',
      'data-[disabled=true]:text-text-disabled',
      'data-[disabled=true]:pointer-events-none',
      ...dataFocusVisibleClasses,
    ],
    label: 'flex gap-1 text-current',
    startContent: '',
  },
  variants: {
    size: {
      md: {
        base: 'py-2 pe-1 ps-2',
        label: 'min-h-6',
      },
      sm: {
        base: 'py-1 pe-1 ps-2 text-sm',
        label: 'min-h-5',
      },
    },
  },
});

export interface ListBoxBaseOptionProps extends FocusableProps, ItemProps<object> {
  className?: string;
}

interface OptionProps<T> extends VariantProps<typeof listBoxItemVariants> {
  item: Node<T>;
  className?: string;
}

/** @private */
export function ListBoxBaseOption<T>(props: OptionProps<T>) {
  const { item, className, size = 'md' } = props;

  const { rendered, key } = item;
  const ElementType: ElementType = item.props.href ? 'a' : 'div';
  const { state, shouldFocusOnHover, shouldUseVirtualFocus } = use(ListBoxContext)!;

  const ref = useRef<any>(undefined);
  const { optionProps, labelProps, isSelected, isDisabled, isFocused, isPressed } = useOption(
    {
      'aria-label': item['aria-label'],
      key,
      isVirtualized: true,
    },
    state,
    ref,
  );

  const { hoverProps, isHovered } = useHover({
    ...props,
    isDisabled,
  });

  const isKeyboardModality = isFocusVisible();

  const styles = useMemo(
    () =>
      listBoxItemVariants({
        className: twMerge(className, item.props.className),
        size,
      }),
    [className, item.props.className, size],
  );

  return (
    <FocusRing>
      <ElementType
        {...mergeProps(optionProps, shouldFocusOnHover ? {} : hoverProps)}
        className={styles.base()}
        data-disabled={isDisabled}
        data-focus={Boolean(shouldUseVirtualFocus && isFocused && isKeyboardModality)}
        data-focus-visible={isKeyboardModality}
        data-hover={(isHovered && !shouldFocusOnHover) || (isFocused && !isKeyboardModality)}
        data-pressed={isPressed}
        data-selectable={state.selectionManager.selectionMode !== 'none'}
        data-selected={isSelected}
        ref={ref}
      >
        {state.selectionManager.selectionMode === 'multiple' ? (
          <FreeSoloCheckbox
            aria-labelledby={labelProps.id}
            isDisabled={isDisabled}
            isReadOnly
            isSelected={isSelected}
          />
        ) : null}
        <div
          {...labelProps}
          className={styles.label()}
        >
          {rendered}
        </div>
      </ElementType>
    </FocusRing>
  );
}

ListBoxBaseOption.displayName = 'ListBoxBaseOption';

import type { Ref } from 'react';

import { Overlay as ReactAriaOverlay } from '@react-aria/overlays';

export interface OverlayProps {
  /**
   * The overlay to render in the portal.
   */
  children: React.ReactNode;
  /**
   * The ref of the element to append the children to.
   */
  container?: Element;
  /**
   * Whether the overlay is open.
   */
  isOpen?: boolean;

  disableFocusManagement?: boolean;

  nodeRef?: Ref<Element | null>;
}

export const Overlay = (inProps: OverlayProps) => {
  const { children, container, disableFocusManagement, nodeRef, isOpen } = inProps;

  return (
    <ReactAriaOverlay
      disableFocusManagement={disableFocusManagement}
      portalContainer={container}
    >
      <div ref={nodeRef as Ref<HTMLDivElement>}>{isOpen ? children : null}</div>
    </ReactAriaOverlay>
  );
};

Overlay.displayName = 'Overlay';

import type { AriaCheckboxProps } from '@react-types/checkbox';
import type { VariantProps } from 'tailwind-variants';
import { useMemo, useRef } from 'react';

import { useCheckbox } from '@react-aria/checkbox';
import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { useToggleState } from '@react-stately/toggle';

import type { DefaultComponentProps } from '../../types';
import { checkboxVariants } from './Checkbox';
import { CheckboxIcon } from './CheckboxIcon';

export type FreeSoloCheckboxProps = DefaultComponentProps<{
  defaultComponent: 'div';
  props: AriaCheckboxProps & VariantProps<typeof checkboxVariants>;
}>;

/**
 * @private
 * @param props
 * @returns
 */
export const FreeSoloCheckbox = (props: FreeSoloCheckboxProps) => {
  const { className, isDisabled, isIndeterminate, isInvalid, size = 'md', ref } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const state = useToggleState(props);
  const { isSelected } = state;
  const { inputProps } = useCheckbox(props, state, inputRef);
  const { hoverProps, isHovered } = useHover({ isDisabled });
  const { pressProps, isPressed } = usePress({ isDisabled: inputProps.disabled });

  const styles = useMemo(
    () =>
      checkboxVariants({
        size,
      }),
    [size],
  );

  return (
    <div
      {...mergeProps(hoverProps, pressProps)}
      className={styles.base({ className })}
      data-disabled={isDisabled}
      data-hover={isHovered}
      data-indeterminate={isIndeterminate}
      data-invalid={isInvalid}
      data-pressed={isPressed}
      data-readonly={inputProps.readOnly}
      data-selected={isSelected || isIndeterminate}
      ref={ref}
    >
      <input
        {...mergeProps(inputProps)}
        className={styles.checkbox()}
        ref={inputRef}
      />

      <span className={styles.iconWrapper()}>
        <CheckboxIcon
          className={styles.icon()}
          isIndeterminate={isIndeterminate}
          isSelected={isSelected}
        />
      </span>
    </div>
  );
};

import type { AriaListBoxOptions } from '@react-aria/listbox';
import type { ListState } from '@react-stately/list';
import type { ReusableView } from '@react-stately/virtualizer';
import type { AriaLabelingProps, DOMProps, FocusStrategy, Node } from '@react-types/shared';
import type { ElementType, HTMLAttributes, ReactElement, ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useCallback, useMemo } from 'react';

import { FocusScope } from '@react-aria/focus';
import { useListBox } from '@react-aria/listbox';
import { mergeProps, useObjectRef } from '@react-aria/utils';
import { Virtualizer, VirtualizerItem } from '@react-aria/virtualizer';
import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverrideProps } from '../../types';
import { createComponent } from '../../utils';
import { ListBoxBaseOption } from './ListBoxBaseOption';
import { ListBoxBaseSection } from './ListBoxBaseSection';
import { ListBoxContext } from './ListBoxContext';
import { ListBoxLayout } from './ListBoxLayout';

const listBoxVariants = tv({
  slots: {
    base: 'rounded-lg',
    loading: 'flex h-full items-center justify-center',
    noResult: 'text-text-disabled',
  },
  variants: {
    size: {
      md: {},
      sm: {},
    },
  },
});

export interface ListBoxBaseTypeMap<
  AdditionalProps = {},
  DefaultComponent extends ElementType = 'div',
> {
  props: AdditionalProps &
    AriaListBoxOptions<object> &
    DOMProps &
    AriaLabelingProps &
    VariantProps<typeof listBoxVariants> & {
      layout: ListBoxLayout<any>;
      state: ListState<object>;
      autoFocus?: boolean | FocusStrategy;
      shouldFocusWrap?: boolean;
      shouldSelectOnPressUp?: boolean;
      focusOnPointerEnter?: boolean;
      domProps?: HTMLAttributes<HTMLElement>;
      disallowEmptySelection?: boolean;
      shouldUseVirtualFocus?: boolean;
      isLoading?: boolean;
      showLoadingSpinner?: boolean;
      onLoadMore?: () => void;
      onScroll?: () => void;
      /**
       * Specifies the loading state content for the combobox. If not provided
       * no text will be displayed in the loading state
       */
      loadingContent?: ReactNode;
      /**
       * Specifies the no results ui elements
       */
      noResultContent?: ReactNode;
    };
  defaultComponent: DefaultComponent;
}

export type ListBoxBaseProps<
  RootComponent extends ElementType = ListBoxBaseTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<ListBoxBaseTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalListBoxBaseProps<
  RootComponent extends ElementType = ListBoxBaseTypeMap['defaultComponent'],
  AdditionalProps = {},
> = InternalComponentProps<ListBoxBaseTypeMap<AdditionalProps, RootComponent>>;

/** @private */
export function useListBoxLayout<T>(size: 'sm' | 'md'): ListBoxLayout<T> {
  const layout = useMemo(
    () =>
      new ListBoxLayout<T>({
        estimatedRowHeight: size === 'sm' ? 32 : 48,
        estimatedHeadingHeight: 33,
        padding: size === 'sm' ? 2 : 8,
        placeholderHeight: size === 'sm' ? 32 : 48,
      }),
    [size],
  );

  return layout;
}

/** @private */
export const ListBoxBase = createComponent<ListBoxBaseTypeMap>(
  <BaseComponentType extends ElementType = ListBoxBaseTypeMap['defaultComponent']>(
    props: InternalListBoxBaseProps<BaseComponentType>,
  ) => {
    const {
      layout,
      state,
      shouldFocusOnHover = false,
      shouldUseVirtualFocus = false,
      domProps = {},
      isLoading,
      showLoadingSpinner = isLoading,
      onScroll,
      className,
      loadingContent,
      noResultContent,
      size = 'md',
      ref,
    } = props;
    const objectRef = useObjectRef(ref);
    const { listBoxProps } = useListBox(
      {
        ...props,
        layoutDelegate: layout,
        isVirtualized: true,
      },
      state,
      objectRef,
    );

    const styles = useMemo(() => listBoxVariants({ className, size }), [className, size]);

    // This overrides collection view's renderWrapper to support hierarchy of items in sections.
    // The header is extracted from the children so it can receive ARIA labeling properties.
    type View = ReusableView<Node<object>, ReactNode>;
    const renderWrapper = useCallback(
      (
        parent: View | null,
        reusableView: View,
        children: View[],
        renderChildren: (views: View[]) => ReactElement[],
      ) => {
        if (reusableView.viewType === 'section') {
          return (
            <ListBoxBaseSection
              headerLayoutInfo={children.find((c) => c.viewType === 'header')?.layoutInfo ?? null}
              item={reusableView.content!}
              key={reusableView.key}
              layoutInfo={reusableView.layoutInfo!}
              size={size}
              virtualizer={reusableView.virtualizer}
            >
              {renderChildren(children.filter((c) => c.viewType === 'item'))}
            </ListBoxBaseSection>
          );
        }

        return (
          <VirtualizerItem
            key={reusableView.key}
            layoutInfo={reusableView.layoutInfo!}
            parent={parent?.layoutInfo}
            virtualizer={reusableView.virtualizer}
          >
            {reusableView.rendered}
          </VirtualizerItem>
        );
      },
      [size],
    );

    const focusedKey = state.selectionManager.focusedKey;

    const persistedKeys = useMemo(
      () => (focusedKey == null ? null : new Set([focusedKey])),
      [focusedKey],
    );

    return (
      <ListBoxContext value={{ state, shouldFocusOnHover, shouldUseVirtualFocus }}>
        <FocusScope>
          <Virtualizer
            {...mergeProps(listBoxProps, domProps)}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={Boolean(props.autoFocus) || undefined}
            className={styles.base()}
            collection={state.collection}
            isLoading={isLoading}
            layout={layout}
            layoutOptions={useMemo(
              () => ({
                isLoading: showLoadingSpinner,
              }),
              [showLoadingSpinner],
            )}
            onLoadMore={props.onLoadMore}
            onScroll={onScroll}
            persistedKeys={persistedKeys}
            ref={objectRef}
            renderWrapper={renderWrapper}
            scrollDirection="vertical"
          >
            {useCallback(
              (type, item: Node<object>) => {
                switch (type) {
                  case 'item': {
                    return (
                      <ListBoxBaseOption
                        item={item}
                        size={size}
                      />
                    );
                  }
                  case 'loader': {
                    return (
                      <div
                        className={styles.loading()}
                        // aria-selected isn't needed here since this option is not selectable.
                        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                        role="option"
                      >
                        {loadingContent}
                      </div>
                    );
                  }
                  case 'placeholder': {
                    return noResultContent ? (
                      <div
                        className={styles.noResult()}
                        // aria-selected isn't needed here since this option is not selectable.
                        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                        role="option"
                      >
                        {noResultContent}
                      </div>
                    ) : null;
                  }
                  default: {
                    return null;
                  }
                }
              },
              [loadingContent, noResultContent, size, styles],
            )}
          </Virtualizer>
        </FocusScope>
      </ListBoxContext>
    );
  },
);

ListBoxBase.displayName = 'ListBoxBase';

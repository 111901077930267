import type { FocusWithinProps } from '@react-aria/interactions';
import type { AriaPopoverProps } from '@react-aria/overlays';
import type { OverlayTriggerState } from '@react-stately/overlays';
import type { ReactNode } from 'react';
import { useMemo, useRef } from 'react';

import { useFocusWithin } from '@react-aria/interactions';
import { DismissButton, usePopover } from '@react-aria/overlays';
import { mergeProps, useObjectRef } from '@react-aria/utils';
import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverrideProps } from '../../types';
import { createComponent } from '../../utils';
import { Overlay } from '../Overlay';
import { Underlay } from '../Underlay';

const popoverVariants = tv({
  base: 'box-border inline-flex flex-col overflow-x-auto overflow-y-hidden rounded-md bg-white shadow-xl outline-0 [clip-path:inset(-30px_-30px)]',
});

export interface PopoverTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'div',
> {
  props: AdditionalProps &
    Omit<AriaPopoverProps, 'popoverRef'> &
    FocusWithinProps & {
      children: ReactNode;
      state: OverlayTriggerState;
      shouldContainFocus?: boolean;
      container?: HTMLElement;
      disableFocusManagement?: boolean;
      enableBothDismissButtons?: boolean;
      onDismissButtonPress?: () => void;
    };
  defaultComponent: DefaultComponent;
}

export type PopoverProps<
  RootComponent extends React.ElementType = PopoverTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<PopoverTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalPopoverProps<AdditionalProps = {}> = InternalComponentProps<
  PopoverTypeMap<AdditionalProps, PopoverTypeMap['defaultComponent']>
>;

export const Popover = createComponent<PopoverTypeMap>((props: InternalPopoverProps) => {
  const {
    children,
    state,
    ref,
    isNonModal,
    enableBothDismissButtons,
    onDismissButtonPress = () => state.close(),
    className,
    style,
    ...otherProps
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isOpen } = state;
  const objRef = useObjectRef(ref);

  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,

      popoverRef: objRef,
    },
    state,
  );

  const { focusWithinProps } = useFocusWithin(props);

  const styles = useMemo(
    () =>
      popoverVariants({
        className,
      }),
    [className],
  );

  return (
    <Overlay
      {...otherProps}
      isOpen={state.isOpen}
      nodeRef={wrapperRef}
    >
      <div ref={wrapperRef}>
        {!isNonModal && (
          <Underlay
            isTransparent
            {...mergeProps(underlayProps)}
            isOpen={isOpen}
          />
        )}
        <div
          {...mergeProps(popoverProps, focusWithinProps)}
          className={styles}
          ref={objRef}
          role="presentation"
          style={{
            ...style,
            ...popoverProps.style,
          }}
        >
          {!isNonModal || enableBothDismissButtons ? (
            <DismissButton onDismiss={onDismissButtonPress} />
          ) : null}
          {children}

          <DismissButton onDismiss={onDismissButtonPress} />
        </div>
      </div>
    </Overlay>
  );
});

Popover.displayName = 'Popover';

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type { HelpTextProps, Validation } from '@react-types/shared';
import type { RefObject } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useCallback, useMemo } from 'react';

import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverrideProps } from '../../types';
import { createComponent } from '../../utils';

const formControlVariants = tv({
  compoundVariants: [
    {
      class: {
        base: 'group relative justify-center data-[has-label=true]:mt-[calc(theme(fontSize.sm)_+_10px)]',
      },
      labelPosition: 'outside',
    },
    {
      class: {
        label: '-left-2 -translate-y-[calc(100%_+_theme(fontSize.sm)/2)] text-sm',
      },
      labelPosition: 'outside',
      shrinkLabel: true,
    },
    {
      class: {
        label: [
          'left-0 group-data-[filled-within=true]:-left-2',
          'text-small',
          'group-data-[filled-within=true]:-translate-y-[calc(100%_+_theme(fontSize.small)/2)]',
        ],
      },
      labelPosition: 'outside',
      shrinkLabel: false,
    },
    {
      class: {
        label:
          'group-data-[filled-within=true]:-translate-y-[calc(50%_+_theme(fontSize.tiny)/2_-_8px)] group-data-[filled-within=true]:scale-90',
      },
      labelPosition: 'inside',
      shrinkLabel: false,
    },
    {
      class: {
        label: '-translate-y-[calc(50%_+_theme(fontSize.tiny)/2_-_8px)] scale-90',
      },
      labelPosition: 'inside',
      shrinkLabel: true,
    },
    {
      class: {
        input: 'h-full',
      },
      labelPosition: 'outside',
    },
  ],
  slots: {
    base: 'group relative m-0 inline-flex flex-col p-0',
    helperText: 'mt-1 block text-xs font-medium text-gray-500 dark:text-gray-400',
    innerWrapper:
      'relative inline-flex w-full flex-row items-center gap-3 tap-highlight-transparent',
    label:
      'pointer-events-none absolute z-10 block origin-top-left select-none ps-2 text-sm font-medium text-gray-700 subpixel-antialiased transition-[transform,color,left,opacity] dark:text-gray-300',
  },
  variants: {
    hasLabel: {
      true: {},
    },
    isFocused: {
      true: {},
    },
    isFullWidth: {
      true: {
        base: 'w-full',
      },
    },
    isInvalid: {
      true: {
        helperText: ['!text-red-500'],
      },
    },
    labelPosition: {
      inside: {},
      outside: {},
    },
    shrinkLabel: {
      true: {},
    },
  },
});

export interface FormControlTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'button',
> {
  props: AdditionalProps &
    Validation<any> &
    HelpTextProps &
    VariantProps<typeof formControlVariants> & {
      /**
       * Helper text appended to the input element.
       */
      helperText?: React.ReactNode;
      /**
       * Props passed to the helper text element.
       */
      helperTextProps?: React.HTMLAttributes<HTMLDivElement>;
      /**
       * Show the required indicator.
       * @default false
       */
      showRequiredIndicator?: boolean;
      /**
       * The required indicator rendered after the label.
       * @default '*'
       */
      requiredIndicator?: React.ReactNode;
      /**
       * The label for the form control input element.
       */
      label?: React.ReactNode;
      /**
       * Props passed to the label element.
       */
      labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
      /**
       * Is form control focused.
       */
      isFocused?: boolean;
      /**
       * If placeholder is present.
       */
      hasPlaceholder?: boolean;
      /**
       * If start content is present.
       */
      hasStartContent?: boolean;
      /**
       * If is filled within.
       */
      isFilledWithin?: boolean;
      /**
       * Shrink label.
       * @default false
       */
      shrinkLabel?: boolean;
      /**
       * Input ref.
       */
      inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
    };
  defaultComponent: DefaultComponent;
}

export type FormControlProps<
  RootComponent extends React.ElementType = FormControlTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<FormControlTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalFormControlProps<AdditionalProps = {}> = InternalComponentProps<
  FormControlTypeMap<AdditionalProps, FormControlTypeMap['defaultComponent']>
>;

export const FormControl = createComponent<FormControlTypeMap>(
  (inProps: InternalFormControlProps) => {
    const {
      as: Component = 'div',
      isInvalid,
      helperText,
      helperTextProps,
      isRequired,
      label,
      labelProps,
      requiredIndicator = '*',
      showRequiredIndicator = false,
      className,
      children,
      labelPosition = 'inside',
      isFocused,
      isFilledWithin,
      hasPlaceholder,
      hasStartContent,
      shrinkLabel = false,
      inputRef,
      isFullWidth = false,
      errorMessage,
      ref,
    } = inProps;

    const styles = useMemo(
      () =>
        formControlVariants({
          className,
          hasLabel: !!label,
          isFocused,
          isFullWidth,
          isInvalid,
          labelPosition,
          shrinkLabel,
        }),
      [className, isFocused, isFullWidth, isInvalid, label, labelPosition, shrinkLabel],
    );

    const handleClick = useCallback(
      (event: MouseEvent) => {
        if (event.target === event.currentTarget) {
          inputRef?.current?.focus();
        }
      },
      [inputRef],
    );

    const labelContent = (
      <label
        {...labelProps}
        className={styles.label({ className: labelProps?.className })}
      >
        {label}
        {showRequiredIndicator && isRequired ? <span aria-hidden>{requiredIndicator}</span> : null}
      </label>
    );

    const helperContent = useMemo(() => {
      const errorContent =
        typeof errorMessage === 'function'
          ? errorMessage({
              isInvalid: isInvalid ?? inputRef?.current?.checkValidity() === false,
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              validationDetails: inputRef?.current?.validity!,
              validationErrors: [],
            })
          : errorMessage;
      if (isInvalid) {
        return errorContent ?? helperText;
      }
    }, [errorMessage, helperText, inputRef, isInvalid]);

    return (
      <Component
        className={styles.base({ className })}
        data-filled-within={
          isFilledWithin || isFocused || hasPlaceholder || hasStartContent || undefined
        }
        data-has-label={!!label || undefined}
        onClick={handleClick}
        ref={ref}
      >
        {label && labelPosition === 'outside' ? labelContent : null}
        <div className={styles.innerWrapper()}>
          {label && labelPosition === 'inside' ? labelContent : null}
          {children}
        </div>
        {helperText ? (
          <div
            {...helperTextProps}
            className={styles.helperText({ className: helperTextProps?.className })}
          >
            {helperContent}
          </div>
        ) : null}
      </Component>
    );
  },
);

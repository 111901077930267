import { Item, Section } from '@react-stately/collections';

import type { OverridableComponent } from '../../types';
import type { ListBoxBaseOptionProps, ListBoxBaseSectionProps } from '../ListBoxBase';
import type { MultipleComboboxProps } from './MultipleCombobox';
import type { SingleComboboxProps } from './SingleCombobox';
import { MultipleCombobox } from './MultipleCombobox';
import { SingleCombobox } from './SingleCombobox';

export interface ComboboxTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'div',
> {
  props:
    | (MultipleComboboxProps<DefaultComponent, AdditionalProps> & { isMultiple: true })
    | (SingleComboboxProps<DefaultComponent, AdditionalProps> & { isMultiple?: false });
  defaultComponent: DefaultComponent;
}

export type ComboboxProps<Multiple extends boolean = false> = Multiple extends true
  ? MultipleComboboxProps
  : SingleComboboxProps;

export const Combobox = (<Multiple extends boolean>(
  props: { isMultiple?: Multiple } & ComboboxProps<Multiple>,
) => {
  const { isMultiple, ref, ...restProps } = props;

  return isMultiple === true ? (
    <MultipleCombobox
      ref={ref}
      {...(restProps as MultipleComboboxProps)}
    />
  ) : (
    <SingleCombobox
      ref={ref}
      {...(restProps as SingleComboboxProps)}
    />
  );
}) as OverridableComponent<ComboboxTypeMap>;

Combobox.displayName = 'Combobox';

export const ComboboxItem = Item as (props: ComboboxItemProps) => React.JSX.Element;

export type ComboboxItemProps = ListBoxBaseOptionProps;

export const ComboboxSection = Section as (props: ComboboxSectionProps) => React.JSX.Element;

export type ComboboxSectionProps = ListBoxBaseSectionProps;

import type { ElementType } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useMemo } from 'react';

import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverrideProps } from '../../types';
import { createComponent } from '../../utils';

const underlayVariants = tv({
  base: 'pointer-events-none fixed inset-0 z-[1] overflow-hidden opacity-0 transition motion-reduce:transition-none motion-reduce:hover:transform-none',
  variants: {
    isOpen: {
      true: '',
    },
    isTransparent: {
      true: '',
    },
  },
  compoundVariants: [
    {
      isOpen: true,
      isTransparent: true,
      className: {
        base: 'pointer-events-auto visible bg-none opacity-[0.9999] transition-none',
      },
    },
    {
      isOpen: true,
      isTransparent: false,
      className: {
        base: 'pointer-events-auto visible opacity-[0.9999] transition-transform-opacity',
      },
    },
  ],
});

export interface UnderlayTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'div',
> {
  props: AdditionalProps & VariantProps<typeof underlayVariants>;
  defaultComponent: DefaultComponent;
}

export type UnderlayProps<
  RootComponent extends ElementType = UnderlayTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<UnderlayTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalUnderlayProps<AdditionalProps = {}> = InternalComponentProps<
  UnderlayTypeMap<AdditionalProps, UnderlayTypeMap['defaultComponent']>
>;

export const Underlay = createComponent<UnderlayTypeMap>((inProps: InternalUnderlayProps) => {
  const {
    as: Component = 'div',
    isOpen = false,
    isTransparent = false,
    className,
    ...props
  } = inProps;

  const styles = useMemo(
    () => underlayVariants({ className, isOpen, isTransparent }),
    [className, isOpen, isTransparent],
  );

  return (
    <Component
      {...props}
      className={styles}
    />
  );
});

Underlay.displayName = 'Underlay';

import type { AriaButtonOptions } from '@react-aria/button';
import type { PressEvent } from '@react-types/shared';
import type { ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useMemo, useRef } from 'react';

import { XMarkIcon } from '@heroicons/react/24/solid';
import { useButton } from '@react-aria/button';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverrideProps } from '../../types';
import { createComponent } from '../../utils';

const chipVariants = tv({
  slots: {
    base: 'group inline-flex max-w-fit items-center justify-between gap-2 rounded-full px-3 py-1 text-xs font-medium',
    content: '',
    removeButton: 'size-4',
    removeButtonIcon: '',
    startContent: '',
  },
  variants: {
    color: {
      default: {
        base: 'bg-gray-200 data-[hover=true]:bg-gray-100',
      },
    },
    isDisabled: {
      true: {
        base: 'pointer-events-none opacity-50',
      },
    },
    size: {
      md: {},
      sm: {
        base: 'py-0.5',
      },
    },
  },
});

export interface ChipTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'div',
> {
  props: AdditionalProps &
    VariantProps<typeof chipVariants> & {
      /**
       * The chip start content.
       */
      startContent?: ReactNode;
      /**
       * Whether the chip is disabled.
       */
      isDisabled?: boolean;
      /**
       * Is the chip removable.
       */
      isRemovable?: boolean;
      /**
       * Handler that is called when the chip is removed.
       */
      onRemove?: (e: PressEvent) => void;
      /**
       * Props passed to the remove button.
       */
      removeButtonProps?: AriaButtonOptions<'button'>;
    };
  defaultComponent: DefaultComponent;
}

export type ChipProps<
  RootComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<ChipTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalChipProps<AdditionalProps = {}> = InternalComponentProps<
  ChipTypeMap<AdditionalProps, ChipTypeMap['defaultComponent']>
>;

export const Chip = createComponent((props: InternalChipProps) => {
  const {
    as: Component = 'div',
    isDisabled,
    removeButtonProps,
    className,
    onRemove,
    children,
    startContent,
    isRemovable = false,
    color = 'default',
    size = 'md',
    ref,
    ...rest
  } = props;

  const { isHovered, hoverProps } = useHover({ isDisabled });
  const removeButtonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps: removeButtonCombinedProps } = useButton(
    {
      ...removeButtonProps,
      isDisabled,
      onPress: onRemove,
    },
    removeButtonRef,
  );

  const styles = useMemo(
    () => chipVariants({ className, color, isDisabled, size }),
    [className, color, isDisabled, size],
  );

  return (
    <Component
      {...mergeProps(hoverProps, rest as Record<string, unknown>)}
      aria-disabled={Boolean(isDisabled) || undefined}
      className={styles.base({ className })}
      data-hover={isHovered}
      ref={ref}
      tabIndex={0}
    >
      {startContent ? <span className={styles.startContent()}>{startContent}</span> : null}
      <span className={styles.content()}>{children}</span>
      {isRemovable ? (
        <button
          type="button"
          {...removeButtonCombinedProps}
          className={styles.removeButton()}
          ref={removeButtonRef}
          tabIndex={-1}
        >
          <XMarkIcon className={styles.removeButtonIcon()} />
        </button>
      ) : null}
    </Component>
  );
});
